import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

import { icons } from './assets/icons';
import { Provider } from 'react-redux';
import axios from 'axios';
import store from './store';
import './polyfill';

import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';

axios.defaults.baseURL = 'https://api.cemilland.com/api/'
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('usertoken')

React.icons = icons

ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
